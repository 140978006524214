export default {
  area: "长安路智慧养老",
  objectName: "",
  //url
  // hostUrl : 'http://cuiyun.dexian.ren/',
  apiHttpsUrl: 'https://lycaljdapi.dexian.ren/',
  apiRequestUrl: 'https://lycaljdapi.dexian.ren/api',
  apiUploadUrl: 'https://lycaljdapi.dexian.ren/api/UploadFile/UploadImgs',
  apiUploadFiles: 'https://lycaljdapi.dexian.ren/api/UploadFile/UploadFiles',
  apiUploadvideo: 'https://lycaljdapi.dexian.ren/api/UploadFile/UploadQiniuVideo',
}